import { getLanguage } from '@/utils'

// POST
const channel_list = {
  en: 'ume-tr-wa-india-en',
  hi: 'ume-tr-wa-india-hindi',
  id: 'ume-tr-wa-indonesia',
  ur: 'ume-tr-wa-pakistan',
  default: 'ume-tr-wa-india-en'
}
const channel = channel_list[getLanguage()] || channel_list.default
const api_key = 'f9cbc16f9f7c69d51b37be84c962d24b64cb0a44'
const source_id = channel + 'wangxin_in'
const view_id = '90d070b22425460dbea1374befbb6f3d'
const placements = [
  { name: 'Editorial Trending Thumbnails-1-1', recCount: 1, organicType: 'mix', thumbnail: { width: 750, height: 350 } },
  { name: 'Editorial Trending Thumbnails-1-2', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } },
  { name: 'AD Editorial Trending Thumbnails-1-1', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } },
  { name: 'Editorial Trending Thumbnails-1-3', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } },
  { name: 'Editorial Trending Thumbnails-1-4', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } },
  { name: 'AD Editorial Trending Thumbnails-1-2', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } }
]

// Analytics
const eventName = 'wangxin_in'

const base_url = 'newsplus.web.sve.cc'

export { channel, api_key, source_id, view_id, placements, eventName, base_url }